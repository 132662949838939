$(function() {
  $('#contacts_datatable').dataTable({
    processing: true,
    serverSide: true,
    ajax: {
      url: $('#contacts_datatable').data('source'),
      contentType: "application/json",
      type: "POST",
      data: function(d) {
        return JSON.stringify(d);
      }
    },
    pagingType: "full_numbers",
    columns: [
      {data: "name"},
      {data: "phone"},
      {data: "data1"},
      {data: "data2"},
      {data: "group"},
      {data: "action", orderable: false}
    ]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });
});