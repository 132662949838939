// import { io } from "socket.io-client";

$(function() {
  var countdown = 0;

  $('#devices_datatable').dataTable({
    processing: true,
    serverSide: true,
    ajax: {
      url: $('#devices_datatable').data('source'),
      contentType: "application/json",
      type: "POST",
      data: function(d) {
        return JSON.stringify(d);
      }
    },
    pagingType: "full_numbers",
    columns: [
      {data: "user"},
      {data: "name"},
      {data: "token_auth", orderable: false},
      {data: "status"},
      {data: "action", orderable: false}
    ]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });

  $('#device_responses').dataTable({
    processing: false,
    sorting: false,
    searching: false,
    serverSide: false,
    pagingType: "full_numbers"
  });

  if(document.getElementById('qrcode')) {
    $('#qrcode').html('<div class="spinner-grow" style="width: 10rem; height: 10rem;" role="status"><span class="sr-only">Loading...</span></div>');
    
    const firstInterval = setInterval(closeScanQrCode, 60000);

    var seconds = 60;
    var display = document.querySelector('#timer');

    startTimer(seconds, display);

    setInterval(getQrCode, 3000);



    // const socket = io(document.getElementById('socket_io_url').value);
    // var init_data = true;
    // socket.on("qrCode", (qrCode) => {
    //   console.log(qrCode)
    //   if(qrCode.session === document.getElementById('session').value) {
    //     $('#qrcode').html('<img src="' + qrCode.data + '" alt="QR Code" />');
    //     console.log(qrCode);

    //     if(init_data) {
    //       clearInterval(firstInterval);
    //       clearInterval(countdown);
          
    //       setInterval(closeScanQrCode, 60000);  

    //       var seconds = 60;
    //       var display = document.querySelector('#timer');
      
    //       startTimer(seconds, display);

    //       init_data = false;
    //     }
    //   }
    // });

    // socket.off("session-logged").on("session-logged", (status) => {
    //   console.log(status);
    //   window.location.href = document.getElementById('url').value + "/devices/" + document.getElementById('device_id').value + "/success_qr";
    // });
  }

  function getQrCode() {
    $.get(document.getElementById('url').value + "/devices/" + document.getElementById('device_id').value + "/get_qr", {}, {}, "script");
  }

  function closeScanQrCode() {
    window.location.href = document.getElementById('url').value + "/devices/cancel_qr";
  }

  function startTimer(duration, display) {
    var start = Date.now(),
        diff,
        minutes,
        seconds;

    function timer() {
      // get the number of seconds that have elapsed since 
      // startTimer() was called
      diff = duration - (((Date.now() - start) / 1000) | 0);

      // does the same job as parseInt truncates the float
      minutes = (diff / 60) | 0;
      seconds = (diff % 60) | 0;

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent = minutes + ":" + seconds; 

      if (diff <= 0) {
        // add one second so that the count down starts at the full duration
        // example 05:00 not 04:59
        start = Date.now() + 1000;
      }
    };
    // we don't want to wait a full second before the timer starts
    timer();
    countdown = setInterval(timer, 1000);
  }
});