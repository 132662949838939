$(function() {
  $('#send_messages_datatable').dataTable({
    processing: true,
    serverSide: true,
    ajax: {
      url: $('#send_messages_datatable').data('source'),
      contentType: "application/json",
      type: "POST",
      data: function(d) {
        return JSON.stringify(d);
      }
    },
    pagingType: "full_numbers",
    columns: [
      {data: "check", orderable: false},
      {data: "datetime"},
      {data: "device"},
      {data: "send_to"},
      {data: "status"},
      {data: "action", orderable: false}
    ],
    order: [[1, 'desc']]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });

  $('#send_message_tos_datatable').dataTable({
    processing: false,
    serverSide: false,
    pagingType: "full_numbers",
    columns: [
      {orderable: false},
      {orderable: true},
      {orderable: true},
      {orderable: true},
      {orderable: true},
      {orderable: true},
      {orderable: true},
      {orderable: false}
    ],
    order: [[1, 'asc']]
  });

  if($("#blasting").val()) {
    $('#send_message_send_type option[value="2"]').attr("selected", "selected");

    $('#number').hide();
    $("#send_message_send_to").attr("required", false);
    $('#contact').hide();
    $("#send_message_contact_id").attr("required", false);
    $('#contact_group').show();
    $("#send_message_contact_group_id").attr("required", true);
    $('#tags1').hide();
    $('#tags2').show();
  } else {
    $('#number').show();
    $("#send_message_send_to").attr("required", true);
    $('#contact').hide();
    $("#send_message_contact_id").attr("required", false);
    $('#contact_group').hide();
    $("#send_message_contact_group_id").attr("required", false);
    $('#tags1').hide();
    $('#tags2').hide();
  }

  window.send_type = function() {
    var selected_item = $('#send_message_send_type').val();

    if(selected_item === "0") {
      $('#number').show();
      $("#send_message_send_to").attr("required", true);
      $('#contact').hide();
      $("#send_message_contact_id").attr("required", false);
      $('#contact_group').hide();
      $("#send_message_contact_group_id").attr("required", false);
      $('#tags1').hide();
      $('#tags2').hide();
    } else if(selected_item === "1") {
      $('#number').hide();
      $("#send_message_send_to").attr("required", false);
      $('#contact').show();
      $("#send_message_contact_id").attr("required", true);
      $('#contact_group').hide();
      $("#send_message_contact_group_id").attr("required", false);
      $('#tags1').show();
      $('#tags2').hide();
    } else if(selected_item === "2") {
      $('#number').hide();
      $("#send_message_send_to").attr("required", false);
      $('#contact').hide();
      $("#send_message_contact_id").attr("required", false);
      $('#contact_group').show();
      $("#send_message_contact_group_id").attr("required", true);
      $('#tags1').hide();
      $('#tags2').show();
    }
  }

  if($('#send_when').val() === "now") {
    $('#send_time').hide();
    $("#send_message_send_time").attr("required", false);
  } else {
    $('#send_time').show();
    $("#send_message_send_time").attr("required", true);
  }

  window.send_messages = function() {
    if($('#send_when').val() === "now") {
      $('#send_time').hide();
      $("#send_message_send_time").val("");
      $("#send_message_send_time").attr("required", false);
    } else {
      $('#send_time').show();
      $("#send_message_send_time").attr("required", true);
    }
  }

  window.insert_tag = function(tag) {
    var $txt = $("#send_message_message");
    var caretPos = $txt[0].selectionStart;
    var textAreaTxt = $txt.val();
    var txtToAdd = tag;
    $txt.val(textAreaTxt.substring(0, caretPos) + txtToAdd + textAreaTxt.substring(caretPos));
  }

  window.check = function() {  
    var ele = document.getElementsByClassName('check-message');  

    if(document.getElementById("check_all").checked) {
      for(var i=0; i<ele.length; i++){  
        if(ele[i].type==='checkbox')  
          ele[i].checked=true;  
      }  
    } else {
      for(var i=0; i<ele.length; i++){  
        if(ele[i].type==='checkbox')  
          ele[i].checked=false;  
      }  
    }
  }
});