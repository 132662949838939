// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// import Chartkick from "chartkick"
// import Chart from "chart.js"
import "chartkick/highcharts"

import "jquery"

var jQuery = require('jquery')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import 'popper.js'
import "bootstrap/dist/js/bootstrap.bundle"
import "jquery.easing/jquery.easing"
import '@fortawesome/fontawesome-free/js/all'

import datatable from 'imports-loader?define=>false!datatables.net'
import datatableBS4 from 'imports-loader?define=>false!datatables.net-bs4'

datatable(window, $)
datatableBS4(window, $)

import hljs from 'highlight.js'
import './shared/sb-admin-2'

import whatsapp from '../../assets/images/whatsapp.svg'
import avatar from '../../assets/images/avatar.jpeg'
import './shared/venom-button.min'

import 'select2'

Rails.start()
// Turbolinks.start()
ActiveStorage.start()
// Chartkick.use(Chart)

$(function() {
  if(document.getElementById("modal01")) {
    document.getElementById("modal01").style.display = "block";
  }
  
  $('.select2').select2({
    theme: "bootstrap"
  });

  $('#send_message_device_id').select2({
    theme: 'bootstrap',
    minimumInputLength: 0,
    dataType: 'json',
    ajax: {
      url: '/devices/get_json',
      delay: 250,
      data: function(params) {
        return { q: params.term, page: params.page }
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
          results: $.map(data.items, function(value, index){
            return {id: value.id, text: value.text};
          }),
          pagination: {
            more: (params.page * 10) < data.total_count
          }
        };
      },
      cache: false
    }
  });

  $('#send_message_contact_id').select2({
    theme: 'bootstrap',
    minimumInputLength: 0,
    dataType: 'json',
    ajax: {
      url: '/contacts/get_json',
      delay: 250,
      data: function(params) {
        return { q: params.term, page: params.page }
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
          results: $.map(data.items, function(value, index){
            return {id: value.id, text: value.text};
          }),
          pagination: {
            more: (params.page * 10) < data.total_count
          }
        };
      },
      cache: false
    }
  });

  $('#send_message_contact_group_id').select2({
    theme: 'bootstrap',
    minimumInputLength: 0,
    dataType: 'json',
    ajax: {
      url: '/contact_groups/get_json',
      delay: 250,
      data: function(params) {
        return { q: params.term, page: params.page }
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
          results: $.map(data.items, function(value, index){
            return {id: value.id, text: value.text};
          }),
          pagination: {
            more: (params.page * 10) < data.total_count
          }
        };
      },
      cache: false
    }
  });

  $('#venom-button').venomButton({
    phone: document.getElementById('cs_wa').value,
    chatMessage: 'Haloo 👋<br><br>Ada yang bisa kami bantu?',
    showPopup: true,
    avatar: avatar,
    buttonImage: whatsapp,
    position: "right",
    headerColor: '#4e73df',
    buttonColor: '#02bfa5',
    // linkButton: false,
    // showOnIE: false,
    nameClient: "Niko",
    headerTitle: 'Tim GetNotif',
    size: "60px",
    zIndex: 999999
  });

  // $('pre, code').append('<span class="command-copy" ><i class="fa fa-clipboard" aria-hidden="true"></i></span>');
  $('code').append('<span class="command-copy" aria-controls="flashMessage"><i class="fa fa-clipboard" aria-hidden="true"></i></span>');

  // $('pre span.command-copy').click(function(e) {
  //   var text = $(this).parent().text().trim();
  //   var copyHex = document.createElement('textarea');
  //   copyHex.value = text
  //   document.body.appendChild(copyHex);
  //   copyHex.select();
  //   document.execCommand('copy');
  //   console.log(copyHex.value)
  //   document.body.removeChild(copyHex);
  // });

  $('code span.command-copy').on('click', function(e) {
    var text = $(this).parent().text().trim(); //.text();
    var copyHex = document.createElement('textarea');
    copyHex.value = text
    document.body.appendChild(copyHex);
    copyHex.select();
    document.execCommand('copy');
    console.log(copyHex.value)
    document.body.removeChild(copyHex);
    alert('Kode berhasil disalin');
  });

  window.loading = function() {
    $('#loader-modal').modal('show');
  }

  window.filter = function() {
    loading();
    document.getElementById('filtering').submit();
  }
});

document.addEventListener('DOMContentLoaded', (event) => {
  document.querySelectorAll('pre code').forEach((el) => {
    hljs.highlightElement(el);
  });
});

// Examples
import './shared/demo/datatables-demo'
import './shared/demo/chart-area-demo'
import './shared/demo/chart-bar-demo'
import './shared/demo/chart-pie-demo'

// Pages
import './contact_group'
import './contact'
import './device'
import './send_message'
import './user'


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
